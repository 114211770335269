import React from 'react';
import './StickyNote.css';
import { getRandomColor } from '../../Utilities/RandomColorGen'; 

const StickyNote = ({ stickyNote, setStickyNote, currentPage, flipbookRef, getRandomColor }) => {
  const addStickyNote = () => {
    const newNote = {
      id: Date.now(),
      text: '',
      x: window.innerWidth / 2 - 100,
      y: window.innerHeight / 2 - 100,
      color: getRandomColor(), 
      page: currentPage, 
      date: new Date()
    };
    setStickyNote([...stickyNote, newNote]);
  };

  const removeStickyNote = (id) => {
    setStickyNote(stickyNote.filter(note => note.id !== id));
  };

  const handleStickyNoteChange = (id, changes) => {
    setStickyNote(stickyNote.map(note => {
      if (note.id === id) {
        if (changes.title && changes.title.length > 14) return note; 
        if (changes.text && changes.text.length > 250) return note; 
        return { ...note, ...changes };
      }
      return note;
    }));
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('text');
    const note = stickyNote.find(note => note.id === parseInt(id));
  
    if (note) {
      const flipbookContainer = flipbookRef.current;
      const rect = flipbookContainer.getBoundingClientRect();
  
      const updatedNote = {
        ...note,
        x: e.clientX - rect.left,
        y: e.clientY - rect.top  
      };
      setStickyNote(stickyNote.map(note => (note.id === parseInt(id) ? updatedNote : note)));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      {stickyNote.filter(note => note.page === currentPage).map(note => {
        const textColor = '#6D6D6D'; 

        return (
          <div
            key={note.id}
            className="sticky-note"
            style={{ 
              left: note.x, 
              top: note.y, 
              backgroundColor: note.color, 
              color: textColor 
            }}
            draggable
            onDragStart={(e) => handleDragStart(e, note.id)}
          >
            <div className="note-header">
              <input
                type="text"
                className="note-title"
                placeholder="Title"
                value={note.title || ''}
                onChange={(e) => handleStickyNoteChange(note.id, { title: e.target.value })}
                maxLength={14} 
                style={{ color: textColor }} 
              />
              <button className="remove-note" onClick={() => removeStickyNote(note.id)}>x</button>
            </div>
            <textarea
              value={note.text}
              onChange={(e) => handleStickyNoteChange(note.id, { text: e.target.value })}
              placeholder="Write your note..."
              maxLength={250} 
              style={{ color: textColor }} 
            />
          </div>
        );
      })}
    </div>
  );
};

export default StickyNote;
