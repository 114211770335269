const navList = [
    {
        _id: 1,
        name: 'Block User',
        icon: 'bi bi-box-arrow-in-right',
    },
    {
        _id: 2,
        name: 'Contacts',
        icon: 'bi bi-person',
    },
    {
        _id: 3,
        name: 'Deliverable',
        icon: 'bi bi-send',
    },
    {
        _id: 4,
        name: 'Feedback',
        icon: 'bi bi-pencil',
        
    },
    {
        _id: 5,
        name: 'Live Chat',
        icon: 'bi bi-mic-fill',
    },
    {
        _id: 6,
        name: 'Upload / Edit Quiz',
        icon: 'bi bi-file-earmark',
        
    },
    
    {
        _id: 7,
        name: 'Settings',
        icon: 'bi bi-gear',
    },
    
    
   

];

 

export default navList;