function Validation(values) {
  let error = {};

 
  const email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  
  const username_pattern = /^(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z]).*\s.*$/;

  if (values.email === "") {
    error.email = "Primary Email Address Required.";
  } else if (values.isPasted) {
    error.email = "Primary Email Address Required.";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Invalid email.";
  } else {
    error.email = "";
  }

  if (values.username !== undefined && values.username !== null) {
    if (values.username === "") {
      error.username = "Required Field.";
    } else if (!username_pattern.test(values.username)) {
      error.username = "Full Legal Name Required";
    } else {
      error.username = "";
    }
  }

  return error;
}

export default Validation;
