import React, { useEffect, useState, useContext } from 'react';
import Header from '../Components/Header/Header';
import FlipBook from '../Components/FlipBook';
import axiosInstance from '../../axiosInstance';
import { UserContext } from '../../UserContext'; 
import {useLocation, useNavigate } from 'react-router-dom';
import AlertBanner from '../Components/Banner/AlertBanner';
import { AtomSpinner } from 'react-epic-spinners';



const API_URL = process.env.REACT_APP_API_URL;
const URL = `${API_URL}/api/convert`;
const SEND = `${API_URL}/api/sticky`;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate(); 

  const { user, setUser, setGoogleDriveID, googleDriveID, setGoogleDriveLink } = useContext(UserContext); 
  const [pdfUrl, setPdfUrl] = useState(''); 
  const [stickyNote, setStickyNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [saveAndFeedback, setSaveAndFeedback] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [hasSaved, setHasSaved] = useState(false); 
  const [loadingPdf, setLoadingPdf] = useState(true); 


  useEffect(() => {
    const initialize = async () => {
      setLoadingPdf(true); 
      const savedEmail = sessionStorage.getItem('userEmail');
      const savedGoogleDriveID = sessionStorage.getItem('googleDriveID');
      
      
      sessionStorage.removeItem('googleDriveLink');
      
    
      if (savedEmail && !user) {
        setUser({ email: savedEmail });
      }

  
      if (savedGoogleDriveID) {
        setGoogleDriveID(savedGoogleDriveID);
      }

      const savedLink = sessionStorage.getItem('googleDriveLink');
      if (savedLink) {
        setPdfUrl(savedLink);
        setGoogleDriveLink(savedLink);
        setLoadingPdf(false); 

        return; 
      }

      if (user && user.email) {
        try {
          const response = await axiosInstance.get(`${URL}?email=${user.email}`, {
            headers: { 'Accept': 'application/json' }, 
          });

          const contentType = response.headers['content-type'];
          console.log('Dashboard content-type:', contentType); 


          if (contentType && contentType.includes('text/html')) {
           
            alert('Session expired. Redirecting to login page.');
            navigate('/login-signup'); 
            return;
          }
          if (response.data && response.data.useSample) {
            setPdfUrl('sample.pdf');
            setGoogleDriveLink('sample.pdf');
            return;
          }
          if (response.data && response.data.useSample) {
            setPdfUrl('sample.pdf');
            setGoogleDriveLink('sample.pdf');
          } else if (response.data && response.data.pdfEndpoint) {
            const pdfEndpoint = response.data.pdfEndpoint;
            setPdfUrl(pdfEndpoint);
            sessionStorage.setItem('googleDriveLink', pdfEndpoint);
            setGoogleDriveLink(pdfEndpoint);

            const extractedID = response.data.googleDriveID || '';
            if (extractedID) {
              setGoogleDriveID(extractedID);
              sessionStorage.setItem('googleDriveID', extractedID);
             
            }
          } else {
            console.error('PDF endpoint is missing in the response data.');
          }
        } catch (error) {
          if (error.response && (error.response.status === 410 || error.response.status === 404)) {
            alert('Session expired. Redirecting to login page.');
            navigate('/login-signup');
          } else {
            console.error('Error fetching PDF:', error);
          }
        } finally {
          setLoadingPdf(false); 
        }
      }
    };

    initialize();
  }, [user, setUser, setGoogleDriveID, setGoogleDriveLink, navigate]);

      

            
  const saveStickyNote = async () => {
    if (!user || !user.email) {
      console.error('User email is not available.');
      alert('Failed to save feedback: Email missing.');
      return;
    }

    if (!googleDriveID) {
      console.error('Google Drive ID is missing.');
      alert('Failed to save your feedback: Document ID is not available.');
      return;
    }

    try {
      const payload = {
        email: user.email,
        googleDriveID,
        stickyNote,
      };

      await axiosInstance.post(`${SEND}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setHasSaved(true);
      alert('Your feedback has been saved successfully!');
      setHasSaved(false);
    
  } catch (error) {
    console.error('Error saving your feedback:', error);
    
    if (error.response && error.response.data) {
      const errorMessage = error.response.data.error;
      
      if (errorMessage === 'The deadline to provide feedback has expired.') {
        alert('Deadline to submit feedback has expired.');
      } else if (errorMessage === 'The order was closed before the deadline, feedback cannot be saved.') {
        alert('The order was closed before the deadline, feedback cannot be saved.');
      } else {
        
        alert('An error occurred while saving your feedback.');
      }
    } else {
      alert('An unexpected error occurred. Please try again later.');
    }
  }
};

  const SaveAndFeedback = async () => {
    await saveStickyNote();
    setShowAlert(false);
   
  };

  const handleHeaderClick = () => {
    return new Promise((resolve) => {
      if (location.pathname === '/dashboard' && stickyNote.length > 0) {
        // Show the alert message before proceeding
        setAlertMessage("You have unsaved sticky notes. Would you like to save them before navigating away?");
        setShowAlert(true);
  
        // Define the cancel logic
        const handleCancel = () => {
          setShowAlert(false); // Hide the alert when cancelled
          resolve(false); // Prevent navigation
        };
  
        // Define the save logic
        const handleSave = async () => {
          await SaveAndFeedback(); // Wait for SaveAndFeedback to complete
          setShowAlert(false); // Hide the alert after saving
          resolve(true); // Allow navigation after saving
        };
  
        // Show options to either save or cancel
        setSaveAndFeedback(() => handleSave); // Set Save function as the logic to execute
        setCancel(() => handleCancel); // Set Cancel function as the logic to execute
      } else {
        resolve(true); // If no unsaved sticky notes, just proceed with navigation
      }
    });
  };

  useEffect(() => {
    return () => {
      setHasSaved(false);
    };
  }, []);

  return (
    <div>
      <Header onHeaderClick={handleHeaderClick} />
      <div>
        {loadingPdf ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full screen height
          }}>
            <AtomSpinner color="#000000" loading={loadingPdf} size={50} />
          </div>
        ) : (
          <FlipBook
            pdfUrl={pdfUrl}
            handleSaveAndFeedback={SaveAndFeedback}
            stickyNote={stickyNote}
            setStickyNote={setStickyNote}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            googleDriveID={googleDriveID}
          />
        )}
      </div>
      <AlertBanner
        showAlert={showAlert}
        showSaveButton={true} 
        handleCancel={cancel}
        handleSaveAndFeedback={saveAndFeedback}
      />
    </div>
  );
};

export default Dashboard;
