import React, { useEffect, useState, useContext, useCallback } from 'react';
import {useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Module from './Module';
import axiosInstance from '../../../axiosInstance';
import { UserContext } from '../../../UserContext'; 
import { calculateScaledScore } from './Score';
import './SAThook.css';
import Lottie from 'lottie-react';
import ProgressBar from './ProgressBar';
import animationData from '../Assets/animationData.json'; 

const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_QUIZ_ID = 'QUIZ_1'; 

const SAThook = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [modules, setModules] = useState([]);
    const [activeModuleIndex, setActiveModuleIndex] = useState(0);
    const moduleTitle = `Module ${activeModuleIndex + 1}`.toUpperCase();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionId, setQuestionId] = useState(null);
    const [question, setQuestion] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [progressCount, setProgressCount] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
    const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState(0); 
    const [correctAnswersInModule, setCorrectAnswersInModule] = useState(0);
    const [skippedQuestions, setSkippedQuestions] = useState(new Set());
    const [hasAnswered, setHasAnswered] = useState(new Set());
    const [showExplanation, setShowExplanation] = useState(false);
    const [explanationText, setExplanationText] = useState('');

    const [isCorrect, setIsCorrect] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState(0);
    const [answerDetails, setAnswerDetails] = useState({    
        timeLeft: null,
        totalPauseDuration: null
    });

    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
    const [quizFinished, setQuizFinished] = useState(false);
    const [scaledScoreState, setScaledScore] = useState(null); 
    const [quizID, setQuizID] = useState(DEFAULT_QUIZ_ID);
    const [noMoreQuizzes, setNoMoreQuizzes] = useState(false); 
    const [pauseCount, setPauseCount] = useState(0);
    const [totalPauseDuration, setTotalPauseDuration] = useState(0); 
    const [extraTimeAfforded, setExtraTimeAfforded] = useState(0);
    const [timeLeft, setTimeLeft] = useState(1920); 
    const [lastQuizCompleted, setLastQuizCompleted] = useState(null);
    
    const [isRestoration, setIsRestoration] = useState(false);

    const onSetSelectedOption = (officialAnswer) => {
        setSelectedOption(officialAnswer);
        
    };
    
    const onSetSkippedQuestions = (updatedSkippedQuestions) => {
        setSkippedQuestions((prevSkipped) => {
            const updatedSkipped = new Set(prevSkipped);
    
            answeredQuestions.forEach((answeredQuestionIndex) => {
                if (updatedSkipped.has(answeredQuestionIndex)) {
                    updatedSkipped.delete(answeredQuestionIndex); // 
                }
            });
    
        
            updatedSkippedQuestions.forEach((questionIndex) => {
                if (!answeredQuestions.has(questionIndex)) {
                    updatedSkipped.add(questionIndex);
                }
            });
    
          
            return updatedSkipped;
        });
    };
    



    useEffect(() => {
        
        axiosInstance.get(`${API_URL}/api/sat-quiz/hook`, {
            params: { quizID: 'QUIZ_1' }  
        })
        .then(response => {
            const parsedQuestions = parseQuestions(response.data);
            setModules(parsedQuestions);
            setActiveModuleIndex(0);
        })
        .catch(error => {
            console.error("Error fetching quiz questions:", error);
        });
    }, []);
    
    const parseQuestions = (data) => {
        
        return data.modules.map((moduleQuestions, moduleIndex) => {
            return moduleQuestions.map(item => ({
                question: item.question,
                passage: item.passage || '',
                options: item.options.map(opt => ({
                    label: opt.label,
                    option: opt.option,
                    explanation: opt.explanation || ''
                })),
                correctAnswer: item.correctAnswer
            }));
        });
    };
    
    

        const handleModuleComplete = () => {
            if (activeModuleIndex === 0){
                setExtraTimeAfforded(totalPauseDuration / 60);
                setProgressCount(totalAnsweredQuestions);
                setTimeLeft(1920);
                
                const nextModuleIndex = 1;
                const moduleQuestions = modules[nextModuleIndex] || [];
                setActiveModuleIndex(nextModuleIndex);
                setQuestion(moduleQuestions);
    
                const nextModuleTitle = `Module ${nextModuleIndex + 1}`.toUpperCase();
            }
        
            if (activeModuleIndex < modules.length - 1) {
               
                setProgressCount(totalAnsweredQuestions);
               
                const nextModuleIndex = activeModuleIndex + 1;
                setActiveModuleIndex(nextModuleIndex);
    
                const nextModuleTitle = `Module ${nextModuleIndex + 1}`.toUpperCase();
    
                setIsRestoration(false);
                 
            } else {
                setQuizFinished(true);
            }
        };
        

    const handleLoginSignup = () => {
        navigate('/login-signup');
    };

    useEffect(() => {
        const answeredCount = answeredQuestions.size; 
        setTotalAnsweredQuestions(answeredCount); 
    }, [answeredQuestions]);

    const handleAnsweredQuestions = ({
        timeLeft, 
        incrementTotalAnswered        
    }) => {
        setAnsweredQuestions(prev => {
            const updatedAnswered = new Set(prev);
            updatedAnswered.add(currentQuestionIndex); 
            return updatedAnswered; 
        });
        setHasAnswered(prev => {
            const updatedHasAnswered = new Set(prev);
            updatedHasAnswered.add(currentQuestionIndex); 
            return updatedHasAnswered; 
        });
        setAnswerDetails({
            timeLeft,    
        });
         if (incrementTotalAnswered) {
        setTotalAnsweredQuestions(prev => {
            const updatedTotal = prev + 1;
            return updatedTotal;
        });
    }
    
        setSkippedQuestions(prevSkipped => {
            const updatedSkipped = new Set(prevSkipped);
            const questionKey = `${activeModuleIndex}-${currentQuestionIndex}`;
            updatedSkipped.delete(questionKey); 
            return updatedSkipped; 
        });
    };

        
useEffect(() => {
    const answeredCount = answeredQuestions.size; 
    setTotalAnsweredQuestions(answeredCount); 
}, [answeredQuestions]);


    const onCorrectAnswer = () => {
        setTotalCorrectAnswers(prev => prev + 1); 
    };

    const handlePauseUpdate = (newPauseCount, newTotalPauseDuration) => {
       
        if (newPauseCount !== null && newPauseCount !== undefined) {
            setPauseCount(newPauseCount); 
        }

        if (newTotalPauseDuration !== null && newTotalPauseDuration !== undefined) {
            setTotalPauseDuration(newTotalPauseDuration); 
            
        }
    };

    useEffect(() => {
        if (quizFinished) {
            const finalScaledScore = totalCorrectAnswers ? calculateScaledScore(totalCorrectAnswers) : 0;

            const progressData = {
                quizFinished: true,
                scaledScore: finalScaledScore,
                lastQuizCompleted: quizID,  
            };

            const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
            localStorage.setItem(progressKey, JSON.stringify(progressData));
        }
    }, [quizFinished, totalCorrectAnswers, quizID]);

    const handleQuizFinished = async () => {
        try {
        setTimeLeft(null);
        const finalScaledScore = calculateScaledScore(totalCorrectAnswers);
        setScaledScore(finalScaledScore);
        } catch (error) {
            console.error('Error saving progress or fetching next quiz:', error);
        }
    };
    return (
        <div className="quiz-container">
             <Helmet>
                <title>Interactive Quiz | Contranorm Media & Production</title>
                <meta name="description" content="Use our interactive Digital SAT preparation material and practice activities designed to help students improve their score by 120 points." />
                <meta name="keywords" content="SAT preparation, how to improve SAT score, free SAT practice, free SAT quiz, SAT practice activities" />
                <link rel="canonical" href="https://contranormdigital.com/sat-hook" />
            </Helmet>
            <div className="confetti-container"></div>
            <ProgressBar 
                answeredCount={progressCount + totalAnsweredQuestions}  
                total={modules.reduce((acc, module) => acc + module.length, 0)}  
                quizFinished={quizFinished}
            />
            {noMoreQuizzes ? (
                <div className="message">
                    <h2>No more quizzes available!</h2>
                </div>
            ) : !quizFinished && modules.length > 0 ? (
                <Module
                    key={activeModuleIndex}
                    moduleTitle={moduleTitle}
                    currentQuestionIndex={currentQuestionIndex}
                    setCurrentQuestionIndex={setCurrentQuestionIndex}
                    moduleTime={timeLeft}
                    onTimeLeftChange={setTimeLeft} 
                    questions={modules[activeModuleIndex]}
                    onAnswerSelected={handleAnsweredQuestions}
                    onCorrectAnswer={onCorrectAnswer} 
                    onComplete={handleModuleComplete}
                    onPauseUpdate={handlePauseUpdate}
                    pauseCount={pauseCount}
                    totalPauseDuration={totalPauseDuration}
                    isRestoration={isRestoration} 
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    onSetSelectedOption={onSetSelectedOption}
                    answeredQuestions={answeredQuestions} 
                    setAnsweredQuestions={setAnsweredQuestions} 
                    hasAnswered={hasAnswered} 
                    setHasAnswered={setHasAnswered}  
                    showExplanation={showExplanation} 
                    setShowExplanation={setShowExplanation} 
                    explanationText={explanationText} 
                    setExplanationText={setExplanationText} 
                    question={question}                    
                    setQuestion={setQuestion}              
                    isCorrect={isCorrect}                  
                    setIsCorrect={setIsCorrect}            
                    correctAnswer={correctAnswer}          
                    setCorrectAnswer={setCorrectAnswer}  
                    correctAnswersInModule={correctAnswersInModule}  
                    setCorrectAnswersInModule={setCorrectAnswersInModule} 
                    skippedQuestions={skippedQuestions} 
                    setSkippedQuestions={setSkippedQuestions}
                    onSetSkippedQuestions={onSetSkippedQuestions}
                    questionId={questionId}
                    setQuestionId={setQuestionId}
                    
                />
           
            ) : quizFinished ? (
                <>
                    <div className="message">
                        <h2>Study Session Complete!</h2>
                        <p>Scaled English & Writing Score:</p>
                        <div className="score">
                            <h2>{calculateScaledScore(totalCorrectAnswers)}</h2>
                        </div>
                    </div>
                    <div className="happy-animation">
                        <Lottie animationData={animationData} loop={true} />
                    </div>
                    <button className="login-signup-button" onClick={handleLoginSignup}>
                        Save your results & continue
                    </button>
                </>
            ) : (
                <p>Loading questions...</p>
            )}
            
            
        </div>
    );
};

export default SAThook;
