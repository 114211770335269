export const calculateScaledScore = (totalCorrectAnswers, totalQuestions = 54) => {
    const rawScore = Math.min(totalCorrectAnswers, totalQuestions); 
  
    const scoreMapping = [
        { rawScore: 54, scaledScore: 800 },
        { rawScore: 52, scaledScore: 740 },
        { rawScore: 50, scaledScore: 720 },
        { rawScore: 48, scaledScore: 680 },
        { rawScore: 45, scaledScore: 650 },
        { rawScore: 43, scaledScore: 640 },
        { rawScore: 41, scaledScore: 630 },
        { rawScore: 39, scaledScore: 610 },
        { rawScore: 37, scaledScore: 590 },
        { rawScore: 35, scaledScore: 580 },
        { rawScore: 33, scaledScore: 570 },
        { rawScore: 31, scaledScore: 560 },
        { rawScore: 29, scaledScore: 550 },
        { rawScore: 27, scaledScore: 540 },
        { rawScore: 25, scaledScore: 530 },
        { rawScore: 23, scaledScore: 520 },
        { rawScore: 21, scaledScore: 510 },
        { rawScore: 19, scaledScore: 500 },
        { rawScore: 17, scaledScore: 490 },
        { rawScore: 15, scaledScore: 480 },
        { rawScore: 13, scaledScore: 460 },
        { rawScore: 11, scaledScore: 420 },
        { rawScore: 9, scaledScore: 380 },
        { rawScore: 7, scaledScore: 320 },
        { rawScore: 5, scaledScore: 280 },
        { rawScore: 3, scaledScore: 220 },
        { rawScore: 1, scaledScore: 210 },
        { rawScore: 0, scaledScore: 200 }
    ];

    
    if (rawScore >= scoreMapping[0].rawScore) {
        return scoreMapping[0].scaledScore;
    }
    if (rawScore <= scoreMapping[scoreMapping.length - 1].rawScore) {
        return scoreMapping[scoreMapping.length - 1].scaledScore;
    }

    
    for (let i = 0; i < scoreMapping.length - 1; i++) {
        const current = scoreMapping[i];
        const next = scoreMapping[i + 1];

        if (rawScore <= current.rawScore && rawScore >= next.rawScore) {
            const rangeRaw = current.rawScore - next.rawScore;
            const rangeScaled = current.scaledScore - next.scaledScore;

            const fraction = (rawScore - next.rawScore) / rangeRaw;
            const interpolatedScore = next.scaledScore + fraction * rangeScaled;

            return Math.round(interpolatedScore);
        }
    }

    return 200; 
};
