import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; 
import './SAT.css'; 

const SAT = () => {
  const [action, setAction] = useState("Digital SAT"); 
  const navigate = useNavigate(); 

  const handleHomeClick = () => {
    navigate('/home');
  };

  const handleDigitalSATClick = () => {
    setAction("Digital SAT"); 
  };

  const handleGEDClick = () => {
    setAction("G.E.D."); 
  };

  const handleBluebookLinkClick = (e) => {
    const confirmNavigation = window.confirm("You are about to navigate away from our site to a third-party service provider. Do you wish to continue?");
    if (!confirmNavigation) {
      e.preventDefault();
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Digital SAT" ? "Digital SAT" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header_text">
          <div className="text">{action === "Digital SAT" ? "Digital SAT" : "Guided Learning Activities"}</div>
          <div className="underline"></div>
        </div>
        
        <>
          {action === "Digital SAT" ? (
            <>
            <br /><br /><br />
            <h4>
                Let our expert linguists show you all you need to know to get the highest score reasonably possible.
              </h4><br />
              <p style={{ textAlign: 'left' }}>
                Preparing for the SAT to study abroad, participate in a dual enrollment program, or enter the University of your choice in the United States can be challenging, but it doesn't have to be. 
              </p><br />
              <p style={{ textAlign: 'left' }}>
                
                <a 
                  href="https://bluebook.collegeboard.org/students/download-bluebook" 
                  className="link-with-space" 
                  onClick={handleBluebookLinkClick}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 Take the SAT and get immediate results.
                </a>
               
              </p>
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <br /><br /><br />
              <h4>
                Gain Continuing Education (CE) credits as you study for the Digital SAT exam.
              </h4><br />
              <p >
                If you are a non-traditional student, an adult learner, or someone interested in Continuing Education (CE), start your journey here by preparing for the exam.
              </p><br />
              <p>After finishing our online practice activities, download your Certificate of Completion or request your Official Transcript using our online tools. And when you're ready, we can help you apply to the college of your choice or complete any additional paperwork required prior to admission.</p>
              <br />
      
            </div>
          )}
        </>
      </div>  
            <div className="submit-container">
        {action === "Digital SAT" ? (
          <>
            <div className="submit gray" onClick={handleHomeClick}>Go Back</div> 
            <div className="submit" onClick={handleGEDClick}>Learn More</div>
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDigitalSATClick}>Go Back</div>
            <Link to="/sat-hook" className="submit">Start Activity</Link> 
          </>
        )}
      </div>

    </Mount>
  );
};

export default SAT;
