import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Card.css';
import CardFilter from './CardFilter';

const Card = ({ card, className, amountClass, children, filterChange }) => {
  const [selectedIndividual, setSelectedIndividual] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);

  let filterOptions = [];

  if (card.name === 'Revenue' || card.name === 'Expenses') {
    filterOptions = ['Day', 'Week', 'Month', 'Year', 'Prior Year'];
  } else if (card.name === 'Statistics') {
    filterOptions = ['Individual', 'Group', 'Month'];
  }

  const handleIndividualSelection = async (email) => {
    setSelectedIndividual(email.trim()); 

    try {
      const response = await axios.get(`http://localhost:8081/api/stars/statistics?filter=individual&email=${email.trim()}`);
      if (response.data && response.data.length > 0 && response.data[0].individualStats) {
        setSelectedRating(response.data[0].individualStats[0].rating);
      } else {
        setSelectedRating('N/A'); 
      }
    } catch (error) {
      console.error('Error fetching individual stats:', error);
      setSelectedRating('N/A');
    }
  };

  const renderItems = () => {
    if (!selectedIndividual && card.name === 'Statistics' && card.filter === 'Individual') {
      const individualData = card.items.find(item => item.type === 'individual');

      return (
        <div className="card-list">
          {individualData && individualData.individuals.length > 0 ? (
            individualData.individuals.map((individual, index) => (
              <div
                key={index}
                className={`list-item ${individual === selectedIndividual ? 'selected' : ''}`}
                onClick={() => handleIndividualSelection(individual)}
              >
                <p>{individual}</p>
              </div>
            ))
          ) : (
            <p className="no-data">No individuals available</p>
          )}
        </div>
      );
    }

    return null;
  };

  const renderAmount = () => {
    if (card.filter === 'Individual' && selectedIndividual) {
      return selectedRating !== null ? selectedRating : 'N/A'; 
    } else if (card.filter === 'Month' && card.items && card.items[0] && card.items[0].monthlyStats) {
      return `${Math.round(card.items[0].monthlyStats.percentage)}%`;
    } else if (card.filter === 'Group' && card.items && card.items[0] && card.items[0].groupStats) {
      return `${Math.round(card.items[0].groupStats.groupPercentage)}%`;
    } else if (typeof card.amount === 'number' && !isNaN(card.amount)) {
      return Math.round(card.amount);
    }
    return card.amount;
  };

  
  useEffect(() => {
    setSelectedIndividual(null);
    setSelectedRating(null);
  }, [card.filter]);

  return (
    <div className={`card ${className}`}>
      {filterOptions.length > 0 && (
        <CardFilter filterChange={filterChange} options={filterOptions} cardName={card.name} />
      )}
      <div className="card-content">
        {card.icon && (
          <div className="icon-container">
            <i className={`card-icon ${card.icon}`} />
          </div>
        )}
        <div>
          <h3>{card.name}</h3>
          <div className="card-amount-wrapper">
            <p className={`card-amount ${amountClass}`}>
              {renderAmount()}
            </p>
            {(!card.items || 
              (card.filter === 'Month' && !card.items[0].monthlyStats) || 
              (card.filter === 'Group' && !card.items[0].groupStats)) ? (
              <p className="no-data"></p>
            ) : null}
          </div>
        </div>
      </div>
      {children}
      {renderItems()}
    </div>
  );
};

export default Card;
