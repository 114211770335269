
import React, { useEffect, useState, useRef } from 'react';
import './Module.css';
import confetti from 'canvas-confetti';

const Module = ({isRestoration, questions, moduleTime, activeModuleIndex, currentQuestionIndex, setCurrentQuestionIndex, onComplete, onAnswerSelected, onCorrectAnswer, onPauseUpdate, pauseCount: restoredPauseCount, totalPauseDuration: restoredTotalPauseDuration, moduleTitle, selectedOption, setSelectedOption, onSetSelectedOption,  selectedOption: initialselectedOption, answeredQuestions, setAnsweredQuestions, question, setQuestion, questionId, setQuestionId, isCorrect, setIsCorrect, correctAnswer, setCorrectAnswer, correctAnswersInModule, setCorrectAnswersInModule, skippedQuestions, setSkippedQuestions, onSetselectedOption, onSetSkippedQuestions, hasAnswered, setHasAnswered, showExplanation, setShowExplanation, explanationText, setExplanationText }) => {

    const previousModuleIndexRef = useRef(activeModuleIndex); 
    const [previousModuleIndex, setPreviousModuleIndex] = useState(activeModuleIndex);

    const [timeLeft, setTimeLeft] = useState(moduleTime);
    const [timerPaused, setTimerPaused] = useState(false); 
    const [RestoredPauseCount, setRestoredPauseCount] = useState(restoredPauseCount || 0); 
    const [RestoredTotalPauseDuration, setRestoredTotalPauseDuration] = useState(restoredTotalPauseDuration || 0); 
    const pauseStartRef = useRef(null); 
    const timerRef = useRef(null);

    useEffect(() => {
        if (isRestoration) {
            setRestoredPauseCount(restoredPauseCount);
            setRestoredTotalPauseDuration(restoredTotalPauseDuration);
        }
    }, [activeModuleIndex, isRestoration]);
    

    const renderOptions = () => {
        return questions[currentQuestionIndex]?.options.map((option, index) => {
            const isSelected = selectedOption[currentQuestionIndex]?.label === option.label;
            const isCorrect = selectedOption[currentQuestionIndex]?.isCorrect;

            return (
                <button
                    key={index}
                    className={`option-button ${isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''}`}
                    onClick={() => handleAnswerSelection(option)}
                    disabled={isRestoration && selectedOption[currentQuestionIndex]?.official} 
                >
                    {option.label}
                </button>
            );
        });
    };

const handlePause = () => {
    pauseStartRef.current = new Date(); 
    setRestoredPauseCount(prev => {
        const newPauseCount = prev + 1; 
        onPauseUpdate(newPauseCount, restoredTotalPauseDuration);
        return newPauseCount;
    });
};


const handleUnpause = () => {
    const pauseEnd = new Date();
    const pauseDuration = (pauseEnd - pauseStartRef.current) / 1000; 
    setRestoredTotalPauseDuration(prev => {
        const newTotalPauseDuration = prev + pauseDuration; 
        onPauseUpdate(restoredPauseCount, newTotalPauseDuration);
        return newTotalPauseDuration;
    });
};

const toggleTimer = () => {
    if (!timerPaused) {
        handlePause(); 
    } else {
        handleUnpause(); 
    }
    setTimerPaused(!timerPaused); 
};


    useEffect(() => {
        if (timerPaused) {
            clearInterval(timerRef.current); 
            return;
        }

        if (answeredQuestions.size === questions.length) {
            clearInterval(timerRef.current);
            handleModuleCompletion();
            return;
        }

        timerRef.current = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timerRef.current);  
                    handleModuleCompletion();
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timerRef.current);  
    }, [timeLeft, onComplete, correctAnswersInModule, timerPaused]);

    const handleAnswerSelection = (option) => {
        const hasSelectedOfficial = selectedOption[currentQuestionIndex]?.official;
    
   
        if (hasSelectedOfficial) {
            handleExplanationDisplay(option);
            return;
        }
    
        const correctAnswer = questions[currentQuestionIndex]?.correctAnswer;
        const isCorrectAnswer = option.label === correctAnswer;
    
      
        const officialAnswer = {
            ...selectedOption,
            [currentQuestionIndex]: { 
                label: option.label, 
                official: true,
                isCorrect: isCorrectAnswer 
            },
        };
    
        setSelectedOption(officialAnswer);
        onSetSelectedOption(officialAnswer);
    
        setIsCorrect(isCorrectAnswer);
        if (isCorrectAnswer) {
            setCorrectAnswersInModule((prev) => prev + 1);
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
                colors: ['#160a3d', '#61dafb', '#a5c5fe', '#ffffff'],
            });
            if (onCorrectAnswer) {
                onCorrectAnswer();
            }
        }
    
        if (!answeredQuestions.has(currentQuestionIndex)) {
            setAnsweredQuestions((prev) => new Set(prev).add(currentQuestionIndex));
            
           
            if (onAnswerSelected) {
                onAnswerSelected({
                    timeLeft,
                  
                  
                    incrementTotalAnswered: true  
                });
            }
        }
    
        handleExplanationDisplay(option);
        if (answeredQuestions.size === questions.length) {
            handleModuleCompletion();
        }
    };
    
    
    const handleExplanationDisplay = (option) => {
        const correctAnswer = questions[currentQuestionIndex]?.correctAnswer;
        const explanation = questions[currentQuestionIndex]?.options.find(opt => opt.label === option.label)?.explanation || '';
    
        
        const isOptionCorrect = option.label === correctAnswer;
    
        setIsCorrect(isOptionCorrect); 
        setExplanationText(explanation);
        setShowExplanation(true);
    };
    

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            const prevIndex = currentQuestionIndex - 1;
    
           
            setCurrentQuestionIndex(prevIndex);
    
           
            setTimeout(() => {
                const prevSelectedOption = selectedOption[prevIndex];
                
                if (prevSelectedOption) {
                    
                    const explanation = questions[prevIndex]?.options.find(opt => opt.label === prevSelectedOption?.label)?.explanation || '';
                    
                    setIsCorrect(prevSelectedOption?.isCorrect); 
                    setExplanationText(explanation);  
                    setShowExplanation(true);
                } else {
                    setIsCorrect(null);
                    setExplanationText('');
                    setShowExplanation(false);
                }
            }, 0);  
        }
    };
    
    const handleNextQuestion = () => {
        if (currentQuestionIndex < questions.length - 1) {
            const nextIndex = currentQuestionIndex + 1;
    
       
            setCurrentQuestionIndex(nextIndex);
    
           
            setTimeout(() => {
                const nextSelectedOption = selectedOption[nextIndex];
    
                if (nextSelectedOption) {
                    
                    const explanation = questions[nextIndex]?.options.find(opt => opt.label === nextSelectedOption?.label)?.explanation || '';
    
                    setIsCorrect(nextSelectedOption?.isCorrect);  
                    setExplanationText(explanation);  
                    setShowExplanation(true);
                } else {
                    setIsCorrect(null);
                    setExplanationText('');
                    setShowExplanation(false);
                }
            }, 0);  
        } else if (answeredQuestions.size === questions.length) {
            onComplete(correctAnswersInModule);
        }
    };
    
    
    const handleShowExplanation = (index) => {
       
        setCurrentQuestionIndex(index);
    
      
        setTimeout(() => {
            const selectedAnswer = selectedOption[index];
    
            if (selectedAnswer) {
                const explanation = questions[index]?.options.find(opt => opt.label === selectedAnswer?.label)?.explanation || '';
    
                setIsCorrect(selectedAnswer?.isCorrect);  
                setExplanationText(explanation);  
                setShowExplanation(true);  
            } else {
                setIsCorrect(null);
                setExplanationText('');
                setShowExplanation(false);  
            }
        }, 0);  
    };
    
    

    const handleModuleCompletion = (restoredTotalPauseDuration, timeLeft) => {
        
        onComplete(correctAnswersInModule, restoredTotalPauseDuration, restoredPauseCount, timeLeft);
        setCurrentQuestionIndex(0);
        setSkippedQuestions(new Set(questions.map((_, index) => index)));
        
        setAnsweredQuestions(new Set());
        setSelectedOption({});
        setIsCorrect(null);
        setShowExplanation(false);
        setCorrectAnswersInModule(0);
       
    };
    
    return (
        <div className="module">
            <div className="top_content">
                <div className="module-header">
                    <h2 className="module-title">{moduleTitle.toUpperCase()}</h2>
                    <div className="date-display">
                        <span>{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                    </div>
                    <div className="timer-container">
                    <span>{Math.floor(timeLeft / 60)}m {timeLeft % 60}s</span>
                    <i
                        className={`bi bi-hourglass-split ${timerPaused ? 'paused' : ''}`} 
                        onClick={toggleTimer}  
                    ></i>
                </div>
                </div>
            </div>
    
            <div className="main_container">
                <div className="card">
                    <div className="card-body">
                        <div className="content-wrapper">
                            <div className="left-content">
                            {questions[currentQuestionIndex]?.image_url && (
                                    <div className="image-container">
                                        <img src={questions[currentQuestionIndex]?.image_url} alt="Question-related visual" />
                                    </div>
                                )}
    
                            {questions[currentQuestionIndex]?.table && (
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    {questions[currentQuestionIndex]?.table.headers.map((header, idx) => (
                                                        <th key={idx}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {questions[currentQuestionIndex]?.table.rows.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {row.map((cell, cellIndex) => (
                                                            <td key={cellIndex}>{cell}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {questions[currentQuestionIndex]?.passage && (
                                    <div className="passage"
                                    dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex]?.passage || '' }}
                               ></div>
                                )}
    
                               
                                
                            </div>
    
                            <div className="content-divider"></div> 
    
                            <div className="right-content"> 
                                <h3 className="card-title" 
                                        dangerouslySetInnerHTML={{ __html: questions[currentQuestionIndex]?.question || 'Loading question...' }}
    >
                                </h3>
    
                               
                                <div className="option-container">
                                    {questions[currentQuestionIndex]?.options.map((option, index) => (
                                        <button
                                            key={index}
                                            className={`btn btn-option ${selectedOption[currentQuestionIndex]?.label === option.label ? 'btn-selected' : ''}`}
                                            onClick={() => handleAnswerSelection(option)}
                                            disabled={false}
                                        >
                                            <span className="option-label">{option.label}</span>
                                            <span className="option-text">{option.option}</span> 
                                        </button>
                                    ))}
                                </div>
                                {showExplanation && (
                                    <div className={`alert ${isCorrect ? 'alert-success' : 'alert-danger'}`}>
                                        <p>{isCorrect ? "Correct!" : "Incorrect!"}</p>
                                        <p className="explanation-text">{explanationText}</p>
                                    </div>
                                )}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
    
            <div className="navigation-buttons">
                <button 
                    className="btn btn-primary" 
                    onClick={handlePrevQuestion} 
                    disabled={currentQuestionIndex === 0 || timerPaused}  
                >
                    <i className="bi-chevron-left"></i>
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={handleNextQuestion} 
                    disabled={timerPaused}  
                >
                    <i className="bi-chevron-right"></i>
                </button>
            </div>
    
            <div className="question-status">
                <div className="question-list">
                {questions.map((_, index) => (
                    <button
                        key={index}
                        className={`btn btn-question-number ${answeredQuestions.has(index) ? 'answered' : 'not-answered'} ${currentQuestionIndex === index ? 'selected' : ''}`}
                        onClick={() => handleShowExplanation(index)}
                        disabled={timerPaused}  
                    >
                        {index + 1}
                    </button>
                ))}
           

                </div>

            </div>
        </div>
    );   
    
};

export default Module;

