import React from 'react';
import './Logo.css';
import logo_silver from '../Assets/logo_silver.png';
import { Link, useNavigate } from 'react-router-dom';

function Logo({ onHeaderClick }) {
    const navigate = useNavigate();
    
    const handleLinkClick = async (e, path) => {
        e.preventDefault();
        
        if (onHeaderClick) {
            const proceed = await onHeaderClick();
            if (proceed) {
                navigate(path);
            }
        } else {
            navigate(path);
        }
    };
    
    return (
        <div className="logo">
            <Link to="/about-us" className="d-flex align-items-center" onClick={(e) => handleLinkClick(e, '/about-us')}>
                <img src={logo_silver} alt="Logo" />
            </Link>
        </div>
    );
}

export default Logo;
