import './ProgressBar.css';

const ProgressBar = ({ answeredCount, total, quizFinished }) => {
   
    const progress = total > 0 ? Math.min((answeredCount / total) * 100, 100) : 0;
  
    if (quizFinished) return null;

    return (
        <div className="progress-wrapper">
            <div className="progress">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                        width: `${progress}%`,
                        background: '#5d1073', 
                        transition: 'width 0.5s ease'
                    }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                />
            </div>
        </div>
    );
};

export default ProgressBar;
