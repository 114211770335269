import React, { useEffect, useState } from "react";
import './MainContent.css';
import { Link } from 'react-router-dom';
import Bubble1 from '../Assets/Bubble_1.png';
import Bubble2 from '../Assets/Bubble_2.png';
import Bubble3 from '../Assets/Bubble_3.png';
import Bubble4 from '../Assets/Bubble_4.png';
import Bubble5 from '../Assets/Bubble_5.png';

const MainContent = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(true);
    }, 100); 
  }, []);

  const handleBubbleClick = () => {
    console.log("Bubble clicked");
  };

  return (
    <div className="mainContent">
      <div className={`container ${isAnimating ? "animate" : ""}`}>
        <div className="box">
          <Link to="/hookc" onClick={handleBubbleClick}>
            <img src={Bubble3} className="Bubble_3" alt="Bubble 1" />
          </Link>
        </div>
        <div className="box">
          <Link to="/hookb" onClick={handleBubbleClick}>
            <img src={Bubble2} className="Bubble_2" alt="Bubble 2" />
          </Link>
        </div>
        <div className="box">
          <Link to="/hook" onClick={handleBubbleClick}>
            <img src={Bubble1} className="Bubble_1" alt="Bubble 3" />
          </Link>
        </div>
        <div className="box">
          <Link to="/sat" onClick={handleBubbleClick}>
            <img src={Bubble4} className="Bubble_4" alt="Bubble 4" />
          </Link>
        </div>
        <div className="box">
          <Link to="/cgi" onClick={handleBubbleClick}>
            <img src={Bubble5} className="Bubble_5" alt="Bubble 5" />
          </Link>
        </div>
        <div className="box">
          <Link to="/hook" onClick={handleBubbleClick}>
            <img src={Bubble1} className="Bubble_6" alt="Bubble 1" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainContent;