import React, { useState, useEffect } from 'react';
import './Nav.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);


const NavNotice = () => {
    const [notificationCount, setNotificationCount] = useState(0);
    


    useEffect(() => {
        const fetchNotification = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/data`); // Adjust URL as needed
                const data = response.data;

                // Count notifications based on the presence of a 'total' field
                const paymentNotification = data.newOrder || [];
                setNotificationCount(paymentNotification.length);
            } catch (error) {
                console.error('Error fetching notification:', error);
            }
        };

        fetchNotification();
        const intervalId = setInterval(fetchNotification, 10000); // Fetch every 10 seconds

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <li className="nav-item">
            <a className="nav-link nav-icon" href="#">
                <i className="bi bi-bell"></i>
                {notificationCount > 0 && (
                    <span className="badge bg-danger badge-number">{notificationCount}</span>
                )}
            </a>
        </li>
    );
};

export default NavNotice;
