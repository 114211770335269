// AboutUs.jsx
import React from 'react';
import './AboutUs.css';
import Header from '../Header/Header';
import abstractSvg from '../Assets/abstract.svg';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleExploreClick = () => {
        navigate('/home'); 
    };

    return (
        <div>
            <Header />
            <div className="stripe"></div>
            <div className="about-us-container">
                <div className="about-us-text">
                <h1>Redefining What’s Possible</h1>
                <p>At Contranorm Media & Production, we’re committed to redefining what’s possible by empowering our clients with creative solutions to overcome the challenges they face every day. From issues related to housing, employment, and academics to entrepreneurship and self-expression, we’ve got your back!</p> <br /> <p>Guided by creativity and driven by innovation, we prioritize a solution-based approach. Leveraging our access to valuable information and skilled professionals, we help individuals like you achieve positive outcomes every day. So, if you believe a skilled writer, independent creator, or advocate can help you overcome a challenge that has  you feeling defeated, overwhelmed, or discouraged, then let us help you start shifting things in your favor today.</p>


                </div>
                <button className="explore" onClick={handleExploreClick}>
                    <span>Explore</span>
                </button>
                <div className="about-us-image-container">
                    <div className="about-us-image">
                        <img src={abstractSvg} alt="Abstract" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
