import React from 'react';

const UserEmail = ({ userEmail, enterEmail, handleEmailPaste, isDisabled }) => (
  <input
    type="email"
    placeholder="Primary Email"
    value={userEmail}
    onChange={enterEmail}
    onPaste={handleEmailPaste}
    disabled={isDisabled || false}
  />
);

export default UserEmail;
