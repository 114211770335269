import React from 'react';
import './Home.css'; // Ensure your CSS file path is correct
import Header from '../Components/Header/Header';
import MainContent from '../Components/MainContent/MainContent';
import Footer from '../Components/Footer/Footer';

const Home = () => {
  return (
    <div className= 'home-container'>
       <Header />
       <MainContent />
      
      
    </div>
  );
};

export default Home;
