import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Mount from '../Mount'; 
import './Privacy.css'; 

const Privacy = () => {
  const [action, setAction] = useState("Privacy");

  const handlePrivacyClick = () => {
    setAction("Privacy"); 
  };
  
  const handleTermsClick = () => {
    setAction("Terms");
  };
  
  return (
    <Mount>
      <Helmet>
        <title>{action === "Privacy" ? "Privacy" : "Terms"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header_text">
          <div className="text">{action === "Privacy" ? "Privacy Policy" : "Terms & Conditions"}</div>
          <div className="underline"></div>
        </div>
        
        <>
          {action === "Privacy" ? (
            <div className="page">
              <p>At Contranorm Media & Production, we believe that your information should be safe, secure, and protected. We work hard to minimize the risks associated with collecting and storing your information.</p>
              <p>One thing that we will never do is sell your data. We recognize that protecting private, personally-identifiable information is a big deal, and we've taken a number of steps to demonstrate our commitment to protecting your privacy.</p>
              <p><Link to="/policies">Learn</Link> &nbsp; how we collect, use, and protect your personal information.</p>
            
            </div>
          ) : (
            <div className="page">
              <p>Our terms cover your use of our services and the legal agreements between you and us.</p>
            
              <p>By soliciting our service(s), accessing our content, using our website(s) or purchasing our product(s), you agree to all terms and conditions outlined in Contranorm Media & Production's Terms & Conditions. This includes accepting any changes to these Terms & Conditions as they are updated and posted.</p>
              <p>Read the full &nbsp; <Link to="/policies">documentation</Link>&nbsp; here.</p>
            </div>
          )}
        </>
      </div>
      
      <div className="submit-container">
        <div className={action === "Privacy" ? "submit gray" : "submit"} onClick={handlePrivacyClick}>Privacy</div>
        <div className={action === "Terms" ? "submit gray" : "submit"} onClick={handleTermsClick}>Terms</div>
      </div>
    </Mount>
  );
};

export default Privacy;
