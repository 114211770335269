// Function to get a random predefined background color
export const getRandomColor = () => {
  const predefinedColors = ['#52154E', '#DAE0F2', '#3AB795', '#DAE0F2', '#52154E', '#EFE9E7', '#E59F71'];
  return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
};


export const getTextColorForBackground = () => {
  return '#6D6D6D'; 
};
