import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './AdminApp.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from './Components/Header';
import SideBar from './Components/SideBar';
import AdminDashboard from './Components/AdminDashboard';
import Main from './Components/Main';
import RegisterDeliverable from './Components/RegisterDeliverable';
import ContactsBanner from './Components/ContactsBanner'; 
import BlockUser from './Components/BlockUser.jsx'; 
import SAT from './Components/SAT.jsx'; 
import Feedback from './Components/Feedback.jsx'; 

function AdminApp() {
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [pageIcon, setPageIcon] = useState('bi bi-grid');
  const [currentView, setCurrentView] = useState('Dashboard');
  const [showDeliverable, setShowDeliverable] = useState(false); 
  const [showContacts, setShowContacts] = useState(false);
  const [showBlockUser, setShowBlockUser] = useState(false); 
  const [showSAT, setShowSAT] = useState(false); 
  const [showFeedback, setShowFeedback] = useState(false); 

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/admin':
        setPageTitle('Dashboard');
        setPageIcon('bi bi-grid');
        break;
      case '/admin/orders':
        setPageTitle('Orders');
        setPageIcon('bi bi-basket');
        break;
      default:
        setPageTitle('Not Found');
        setPageIcon('bi bi-exclamation-circle');
        break;
    }
  }, [location.pathname]);

  const handleNavClick = (view) => {
    if (view === 'Deliverable') {
      setShowDeliverable(true);
      setShowContacts(false);
      setShowBlockUser(false);
      setShowSAT(false);
      setShowFeedback(false);
    } else if (view === 'Contacts') {
      setShowContacts(true);
      setShowDeliverable(false);
      setShowBlockUser(false);
      setShowSAT(false);
      setShowFeedback(false);
    } else if (view === 'Block User') {
      setShowBlockUser(true);
      setShowDeliverable(false);
      setShowContacts(false);
      setShowSAT(false);
      setShowFeedback(false);
    } else if (view === 'Upload / Edit Quiz') {
      setShowSAT(true);
      setShowBlockUser(false);
      setShowDeliverable(false);
      setShowContacts(false);
      setShowFeedback(false);
    } else if (view === 'Feedback') {
      setShowFeedback(true);
      setShowSAT(false);
      setShowBlockUser(false);
      setShowDeliverable(false);
      setShowContacts(false);
    } else {
      setCurrentView(view);
      setShowDeliverable(false);
      setShowContacts(false);
      setShowBlockUser(false);
      setShowSAT(false);
    }
  };

  const handleCloseRegister = () => {
    setShowDeliverable(false); 
  };

  const handleCloseContacts = () => {
    setShowContacts(false); 
  };
  const handleCloseBlockUser = () => {
    setShowBlockUser(false);
  };

  const handleCloseSAT = () => {
    setShowSAT(false); 
  };
  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  return (
    <div className="admin-container">
      <Header className="header" />
      <SideBar onNavClick={handleNavClick} className="sidebar" />
      <div className="main-content">
        <Main pageTitle={pageTitle} pageIcon={pageIcon} />
        {showDeliverable && <RegisterDeliverable onClose={handleCloseRegister} />}
        {showContacts && <ContactsBanner onClose={handleCloseContacts} />}
        {showBlockUser && <BlockUser onClose={handleCloseBlockUser} />} 
        {showSAT && <SAT onClose={handleCloseSAT} />}
        {showFeedback && <Feedback onClose={handleCloseFeedback} />}
        {!showDeliverable && !showContacts && !showBlockUser && !showSAT && !showFeedback && (
          <Routes>
            <Route exact path="/admin" element={<AdminDashboard />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default AdminApp;
