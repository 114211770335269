import React, { useEffect, useState } from 'react';
import videoSrc from '../Assets/404Ether.mp4';
import './Error404.css';

const Error404 = () => {
  const [visible, setVisible] = useState(false); 
  const [fadeOut, setFadeOut] = useState(false); 

  useEffect(() => {
    setVisible(true); 

   
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  
  const handleAnimationEnd = () => {
    if (fadeOut) {
      setVisible(false); 
    }
  };

  return (
    <div className="errorContainer">
      <video autoPlay loop muted className="backgroundVideo">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {visible && (
        <p className={fadeOut ? 'fadeOut' : ''} onAnimationEnd={handleAnimationEnd}>
           This page is transitioning from imagination to reality. 
        </p>
      )}
    </div>
  );
};

export default Error404;
