import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'turn.js';
import './FlipBook.css';
import * as pdfjsLib from 'pdfjs-dist';
import { WorkerMessageHandler } from 'pdfjs-dist/build/pdf.worker.min.mjs';
import pageTurn from './Assets/pageTurn.mp3';
import StickyNote from './StickyNote/StickyNote';
import FBFooter from './Footer/FBFooter';
import FlipBookSidebar from './Sidebar/FlipBookSidebar';
import { getRandomColor } from '../Utilities/RandomColorGen.js'; 
import { AtomSpinner } from 'react-epic-spinners';


const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);

pdfjsLib.GlobalWorkerOptions.workerSrc = URL.createObjectURL(new Blob([WorkerMessageHandler], { type: 'application/javascript' }));

const FlipBook = ({ pdfUrl, stickyNote, setStickyNote, googleDriveID, handleSaveAndFeedback }) => {
  const audioRef = useRef(new Audio(pageTurn));
  const [soundEnabled, setSoundEnabled] = useState(true);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const flipbookRef = useRef(null);
  const [flipbookInitialized, setFlipbookInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState('thumbnails');
  const [activeThumbnail, setActiveThumbnail] = useState(null);

  const maxWidth = 800;
  const maxHeight = 540;

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
        const numPages = pdf.numPages;

        const renderPromises = [];
        for (let i = 1; i <= numPages; i++) {
          renderPromises.push(pdf.getPage(i).then(page => {
            const viewport = page.getViewport({ scale: 3 });
            const canvas = document.createElement('canvas');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const context = canvas.getContext('2d');

            return page.render({ canvasContext: context, viewport }).promise.then(() => canvas.toDataURL());
          }));
        }

        const pagesData = await Promise.all(renderPromises);
        setPages(pagesData);
        setLoading(false);
        setTotalPages(numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  const calculateHorizontalCenteringStyles = () => {
    const viewportWidth = window.innerWidth;
    const singlePageWidth = maxWidth / 2; 
    const marginLeft = Math.max(0, (viewportWidth - singlePageWidth) / 2);
    
    return {
      marginLeft: `${marginLeft}px`,
    };
  };
  

  useEffect(() => {
    if (!loading && !flipbookInitialized) {
      const flipbook = $(flipbookRef.current);

      if (flipbook.length && !flipbook.data('turn')) {
        const setDisplayMode = (page) => {
          if (page === 1 || page === pages.length) {
            flipbook.turn('display', 'single');
            flipbook.turn('size', maxWidth / 2, maxHeight);
  
            const centeringStyles = calculateHorizontalCenteringStyles();
            flipbook.css(centeringStyles);
          } else {
            flipbook.turn('display', 'double');
            flipbook.turn('size', maxWidth, maxHeight);
          }
        };
  
       
        flipbook.turn({
          width: maxWidth,
          height: maxHeight,
          autoCenter: true,
          acceleration: true,
          duration: 900,
          display: 'double',
          gradients: true,
          elevation: 50,
          pages: pages.length,
          when: {
            turning: (event, page, view) => {
              setDisplayMode(page);
              console.log('Page turning:', page);
              if (page < 1 || page > pages.length) {
                console.error('Invalid page number:', page);
                event.preventDefault();
                return;
              }
              setCurrentPage(page);
              setActiveThumbnail(page - 1);
  
              if (soundEnabled) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current.play().catch(error => console.error('Audio playback error:', error));
              }
            },
            turned: (event, page, view) => {
              setDisplayMode(page);
              console.log('Page turned:', page);
            }
          }
        });
  
        setDisplayMode(1);
        setFlipbookInitialized(true);
      }
  
  
      return () => {
        if (flipbook.length && flipbook.data('turn')) {
          console.log('Destroying turn.js instance...');
          flipbook.turn('destroy');
        }
      };
    }
  }, [loading, flipbookInitialized, pages, currentPage, soundEnabled]);
  
  
  

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

 
  
  const addStickyNote = () => {
    const newNote = {
      id: Date.now(),
      text: '',
      x: `${Math.random() * (window.innerWidth - 200)}px`, 
      y: `${Math.random() * (window.innerHeight - 200)}px`, 
      color: getRandomColor(),
      page: currentPage,
      date: new Date()
    };
    setStickyNote([...stickyNote, newNote]);
  };
  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('text');
    const note = stickyNote.find(note => note.id === parseInt(id));

    if (note) {
      const flipbookContainer = flipbookRef.current;
      const rect = flipbookContainer.getBoundingClientRect();

      const updatedNote = {
        ...note,
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
      setStickyNote(stickyNote.map(note => (note.id === parseInt(id) ? updatedNote : note)));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleThumbnailClick = (page) => {
    if (page >= 0 && page < pages.length) { 
        console.log(`Thumbnail clicked: ${page}`); 
        setActiveThumbnail(page);
        $(flipbookRef.current).turn('page', page + 1);
      } else {
          console.error('Invalid page number:', page + 1);
      }
  };


  return (
    <div className='Mcontainer' onDragOver={handleDragOver} onDrop={handleDrop}>
      <div ref={flipbookRef}  className="flipbook-container">
        {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', 
                }}
            >
              <AtomSpinner color="#ffffff" size={60} /> 
            </div>
        ) : (
             <div className="flipbook-wrapper">
                      <div className="flipbook" ref={flipbookRef}>
                        {pages.map((src, page) => (
                            <div key={page} className="flipBookpage">
                             <img src={src} alt={`Page ${page + 1}`} />
                              {page % 1 === 0 && <div className="page-gradient left-gradient"></div>}
                             {(page + 1) % 2 === 0 && <div className="page-gradient right-gradient">
                            </div>}
                        </div>
                      ))}
                </div>
              </div>
              )}
              <FlipBookSidebar
                  pages={pages}
                  activeThumbnail={activeThumbnail}
                  handleThumbnailClick={handleThumbnailClick}
                  sidebarVisible={sidebarVisible}
                  toggleSidebar={toggleSidebar}
                  sidebarContent={sidebarContent}
                  calculateHorizontalCenteringStyles={calculateHorizontalCenteringStyles}
                />
              </div>
            <StickyNote
                    stickyNote={stickyNote}
                    setStickyNote={setStickyNote}
                    currentPage={currentPage}
                    flipbookRef={flipbookRef}
                    getRandomColor={getRandomColor}
            />
            <FBFooter
              pdfUrl={pdfUrl} 
              flipbookRef={flipbookRef}
              pages={pages} 
              currentPage={currentPage}
              totalPages={totalPages}
              addStickyNote={addStickyNote}
              soundEnabled={soundEnabled}
              toggleSound={() => setSoundEnabled(!soundEnabled)}
              toggleSidebar={toggleSidebar}
              sidebarVisible={sidebarVisible}
              googleDriveID={googleDriveID}
              handleSaveAndFeedback={handleSaveAndFeedback}
            />
    </div>
  );
};

export default FlipBook;
