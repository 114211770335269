// src/axiosInstance.js
import axios from 'axios';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});


const retryRequest = async (error) => {
  if (!error.config || error.config.__retryCount >= 1) {
    return Promise.reject(error); 
  }

  error.config.__retryCount = (error.config.__retryCount || 0) + 1;

  return new Promise((resolve) => {
    setTimeout(() => resolve(instance(error.config)), 1000); 
  });
};


instance.interceptors.response.use(
  (response) => {
    const contentType = response.headers['content-type'];

    if (contentType && contentType.includes('text/html')) {
      console.log('Interceptor detected HTML response');
    
      return Promise.reject({ message: 'Session expired', sessionExpired: true });
    }

    return response; 
  },
  async (error) => {
    if (error.code === 'ECONNRESET' || error.message.includes('Network Error')) {
      console.warn('Connection reset or network error; retrying...');
      return retryRequest(error);
    }

    if (error.response && error.response.status === 410) {
      console.error('Interceptor detected 410 Gone response');
     
      return Promise.reject({ message: 'File no longer available', fileGone: true });
    }

   
    console.error('Interceptor error:', error);
    return Promise.reject(error);
  }
);



export default instance;
