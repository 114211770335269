import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'; 
const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  //<React.StrictMode>
    <Router>
      <App />
    </Router>
  //</React.StrictMode>
);

reportWebVitals();
